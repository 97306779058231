<template>
    <div>
        <Navbar mode="solid" :class="navbar" :show-btn-icon="true" :show-avatar="false" :show-btns="false"
            :btn-link-path="backPath" />
        <main class="main" v-if="isSuccess === false">
            <div class="wrapper">
                <h1 class="page-title" v-t="'page.tour.book.login_signup'" />

                    <p class="page-description" v-t="'page.tour.book.with_a_travel_afrique_account'" />
                <div class="form-page">
                    <div class="contact">
                            <Button kind="basic" typ :text="`${$t('cta.login')}`" class="w-full mb-8 py-3"
                                type="button" @click.native="openLogin()" />
                            <div class="flex items-center mb-8">
                                <Divider />
                                <span class="px-4 text-lg text-black-lighter">
                                    {{ $t("trip_request.form_6.login.alt") }}
                                </span>
                                <Divider />
                            </div>
                            <Button kind="primary" typ :text="$t('cta.signup')" class="w-full mb-4 py-3"
                                    type="button" @click.native="openSignup()" />
                            <Button kind="secondary" typ :text="$t('cta.guest_checkout')" class="w-full mb-8 py-3"
                                        type="button" @click.native="startBooking()" />
                    </div>
                </div>
            </div>
        </main>
        <main class="main" v-if="isSuccess === true && isLogin === true">
            <div class="wrapper flex justify-center items-center flex-col">
                <div class="icon-container">
                    <i class="bx bx-check"></i>
                </div>
                <h1 class="page-title" v-t="'success.you_have_logged_in'" />
                <p class="page-description mt-4" v-t="'system.please_wait_redirect'" />
                <div>
                    <i class="bx bx-loader-alt text-4xl bx-spin text-teal-base"></i>
                </div>
            </div>
        </main>
        <main class="main" v-if="isSuccess === true && isSignup === true">
            <div class="wrapper flex justify-center items-center flex-col">
                <div class="icon-container">
                    <i class="bx bx-check"></i>
                </div>
                <h1 class="page-title" v-t="'success.you_have_logged_in'" />
                <p class="page-description mt-4" v-t="'system.please_wait_redirect'" />
                <div>
                    <i class="bx bx-loader-alt text-4xl bx-spin text-teal-base"></i>
                </div>
            </div>
        </main>
        <BackgroundLayer v-if="showBackgroundLayer" class="fixed" @click.native="closeModals()" />
        <SignupModal
            v-if="showSignup"
            :redirect="false"
            class="fixed z-30"
            @closemodal="closeModals()"
            @goToLogin="closeModals(), openLogin()"
            @subscribed="startBookingAfterAuth(), setSignup()"
        />
        <ResetPasswordModal 
            v-if="showReset" 
            :showConfirmation="showResetConfirmation"
            :showReset="showReset" 
            class="fixed z-30" 
            @closemodal="closeResetPassword"
            @goToLogin="closeModals(), openLogin()" 
            @goToSignup="closeModals(), openSignup()"
            />
        <LoginModal 
            v-if="showLogin" 
            class="fixed z-30"
            @closemodal="closeModals()"
            @goToforgotPassword="closeModals(), openReset()"
            @goToSignup="closeModals(), openSignup()"
            :redirect="false"
            @logged="startBookingAfterAuth(), setLogin()"
        />
    </div>
</template>

<script>
import Navbar from "@/components/layout/Navbar.vue";
import LoginModal from "@/components/modals/LoginModal";
import BackgroundLayer from "@/components/BackgroundLayer";
import ResetPasswordModal from "@/components/modals/ResetPasswordModal";
import Button from "@/components/buttons/Button";
import Divider from "@/components/dividers/Divider";
import { mapGetters } from "vuex";
import SignupModal from "@/components/modals/SignupModal";
export default {
    name: "TourDates",
    components: {
        Navbar,
        BackgroundLayer,
        ResetPasswordModal,
        LoginModal,
        Button,
        Divider,
        SignupModal
    },
    data() {
        return {
            showLogin: false,
            showSignup: false,
            showBackgroundLayer: false,
            showReset: false,
            isSuccess: false,
            isLogin: false,
            isSignup: false,
        }
    },
    methods: {
        setSignup() {
            this.closeModals();
            this.isSuccess = this.isSignup = true;  
        },
        setLogin() {
            this.closeModals();
            this.isSuccess = this.isLogin = true;  
        },
        openLogin() {
            this.showBackgroundLayer = this.showLogin = true;
        },
        openSignup() {
            this.showBackgroundLayer = this.showSignup = true;
        },
        openReset() {
            this.showBackgroundLayer = this.showReset = true;
        },
        closeModals() {
            this.showReset = this.showLogin = this.showBackgroundLayer = this.showSignup = false;
        },
        startBooking() {
            this.$router.push(this.redirectPath);
        },
        startBookingAfterAuth() {
            setTimeout(this.startBooking, 3000);
        }
    },
    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
        redirectPath() {
            let query = this.$route.query;
            let origin = query["origin"];
            let dateId = query["date_id"];
            let tripDate = query["trip_date"];
            let id = query["id"];
            return `/book/details?id=${id}&date_id=${dateId}&trip_date=${tripDate}&origin=${origin}`;
        },
        navbar() {
            if (this.isSuccess === true)
                return "navbar-pointer";
            else
                return "navbar";
        },
        backPath() {
            if (this.$route.query.origin === "dates")
                return `/book/dates?id=${this.$route.query.id}&origin=dates`;

            return `/tour?id=${this.$route.query.id}`;
        },
    },
    created() {
        if (this.isAuthenticated === true) {
            this.startBooking();
        }
    }
};
</script>

<style scoped>
.main {
    @apply pt-16 pb-32 px-4;
}

.page-title {
    @apply font-bold text-3xl text-black-base text-center;
}

.page-description {
    @apply text-black-lighter text-lg mb-12 text-center;
}

.wrapper {
    @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}

.navbar {
    @apply border-none;
}

.navbar-pointer{
    @apply pointer-events-none opacity-50;
}

.icon-container{
    @apply bg-green-base h-16 w-16 flex justify-center items-center;
    @apply text-3xl text-white rounded-full mb-6;
}

</style>